.availability {
  width: 100%;
  padding: 5rem 0 10rem 0;
}
.availability__title {
  font-size: 2.5rem;
  border-bottom: 0.1rem solid rgba(153, 153, 153, 0.205);
  padding-bottom: 0.5rem;
}
.availability__data {
  display: flex;
  flex-wrap: wrap;
}
.availability__data--row {
  padding-left: 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}
.availability__data--row--icon-container {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ccc;
  padding: 0.5rem 1rem;
  border: 0.2rem solid #ccc;
  margin-right: 1.5rem;
}
.availability__data--row--text {
  word-break: break-all;
}
.availability__data--row .link {
  color: #ccc;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
}
.availability__data--row .link:hover {
  color: #df2318;
}
