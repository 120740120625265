@font-face {
    font-family: "Open Sans";
    font-weight: 400;
    font-style: normal;
    src: url("./Open_Sans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Open Sans Bold";
    font-weight: 600;
    src: url("./Open_Sans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Open Sans Light";
    font-weight: 300;
    font-style: normal;
    src: url("./Open_Sans/OpenSans-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Open Sans Italic";
    font-style: italic;
    src: url("./Open_Sans/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Open Sans Semi Bold";
    font-weight: 600;
    src: url("./Open_Sans/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Open Sans Extra Bold";
    font-weight: 800;
    font-style: normal;
    src: url("./Open_Sans/OpenSans-ExtraBold.ttf") format("truetype");
}
