.button-wrapper {
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
}
.button-wrapper--right {
  justify-content: flex-end;
}
.button-wrapper--left {
  justify-content: flex-start;
}
.xa-button {
  font-size: 1.2rem;
  outline: none;
  padding: 1.5rem 2rem;
  border: none;
  cursor: pointer;
  -webkit-border-radius: 0.7rem;
  -moz-border-radius: 0.7rem;
  border-radius: 0.7rem;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}
.xa-button:focus {
  outline: none;
}
.xa-button__wide-button {
  padding: 1rem 4rem;
}
.xa-button__alt-color {
  border: none;
}
.xa-button__text-type {
  padding: 0;
  background-color: transparent;
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.xa-button__text-type:hover {
  background-color: transparent;
}
.xa-button__link-button {
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  background-color: transparent;
}
.xa-button__full-width {
  width: 100%;
  display: block;
}
.xa-button:disabled {
  background-color: #666;
  cursor: not-allowed;
}
