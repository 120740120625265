.warn-page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.warn-page {
  max-width: 50rem;
  margin: 0 auto;
  padding: 2rem 4rem;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 3rem;
  color: #ccc;
  background-color: whitesmoke;
}
.warn-page__title {
  width: 100%;
  text-align: center;
  font-size: 2rem;
}
.warn-page__title span {
  font-size: 4rem;
}
.warn-page__container--text {
  font-size: 1.6rem;
  font-weight: 400;
  text-align: justify;
  margin: 1rem auto;
  color: #888;
}
.warn-page__container--text span {
  font-weight: 600;
  font-size: 1.65rem;
}
.warn-page__container--buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.warn-page__container--buttons .xa-button:last-of-type {
  margin-left: 2rem;
}
@media screen and (max-width: 400px) {
  .warn-page__container--buttons {
    flex-direction: column;
  }
  .warn-page__container--buttons .xa-button:last-of-type {
    margin-left: 0;
    margin-top: 1rem;
  }
}
