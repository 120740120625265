/*
  	Flaticon icon font: Flaticon
  	Creation date: 17/08/2020 15:45
  	*/

@font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.eot");
    src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
        url("./Flaticon.woff2") format("woff2"), url("./Flaticon.woff") format("woff"),
        url("./Flaticon.ttf") format("truetype"), url("./Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        src: url("./Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 2rem;
    font-style: normal;
}

.flaticon-location-pin:before {
    content: "\f100";
}
.flaticon-phone-call:before {
    content: "\f101";
}
.flaticon-email:before {
    content: "\f102";
}
.flaticon-facebook:before {
    content: "\f103";
}
.flaticon-instagram:before {
    content: "\f104";
}
