::-webkit-scrollbar {
  width: 0.8rem;
}
::-webkit-scrollbar-track {
  background: #ccc;
}
::-webkit-scrollbar-thumb {
  background: #df2318;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #e83329;
}
html {
  height: 100vh;
  box-sizing: border-box;
  font-size: 10px;
  background-color: transparent;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
body {
  box-sizing: border-box;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: relative;
  margin: 0;
  height: 100vh;
  font-family: "Open Sans", sans-serif;
  background-color: #111;
  color: whitesmoke;
  background-image: -moz-linear-gradient(180deg, black 0%, #000000 60%, rgba(30, 30, 30, 0.1) 100%);
  background-image: -webkit-gradient(180deg, black 0%, #000000 60%, rgba(30, 30, 30, 0.1) 100%);
  background-image: -webkit-linear-gradient(180deg, black 0%, #000000 60%, rgba(30, 30, 30, 0.1) 100%);
  background-image: -o-linear-gradient(180deg, black 0%, #000000 60%, rgba(30, 30, 30, 0.1) 100%);
  background-image: -ms-linear-gradient(180deg, black 0%, #000000 60%, rgba(30, 30, 30, 0.1) 100%);
  background-image: linear-gradient(180deg, black 0%, #000000 60%, rgba(30, 30, 30, 0.1) 100%);
}
#root {
  height: 100vh;
  overflow: hidden;
}
*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none !important;
}
*:focus,
*::before:focus,
*::after:focus {
  outline: none !important;
}
/* Built-in css created arrow */
/* Use the border-color property to color it */
.arrow {
  border: solid #888;
  border-width: 0 0.5rem 0.5rem 0;
  display: inline-block;
  padding: 0.5rem;
  cursor: pointer;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.arrow:hover {
  border-color: transparent #ccc #ccc transparent;
}
.threads {
  cursor: pointer;
}
.threads .thread {
  width: 2rem;
  height: 0.3rem;
  display: block;
  margin: 0.3rem 0;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  background-color: #888;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.threads:hover .thread {
  background-color: #ccc;
}
/* Built-in css created close element. Displays an X */
/* Use the background and border-color property to color it */
.exit {
  width: 2.25rem;
  height: 2.25rem;
  background-color: transparent;
  color: #888;
  cursor: pointer;
  position: relative;
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.exit::after {
  position: absolute;
  content: "\d7";
  font-weight: bold;
  top: -0.9rem;
  left: 0;
  font-size: 3.6rem;
}
.exit:hover {
  color: #ccc;
}
.no-data {
  padding: 4rem;
  position: relative;
  margin: 0 auto;
  font-size: 1.4rem;
  border: 0.2rem solid rgba(153, 153, 153, 0.205);
  text-align: center;
  width: 100%;
}
button {
  outline: none !important;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
button:focus {
  outline: none !important;
}
input {
  outline: none !important;
  -webkit-transition: border 0.3s linear;
  -moz-transition: border 0.3s linear;
  -ms-transition: border 0.3s linear;
  -o-transition: border 0.3s linear;
  transition: border 0.3s linear;
}
input:focus {
  outline: none !important;
}
a {
  text-decoration: none;
  outline: none !important;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
  color: #ccc;
}
a:hover {
  text-decoration: none;
  outline: none !important;
  color: #d9d9d9;
}
a:focus {
  outline: none !important;
}
a:visited {
  outline: none !important;
}
.layout {
  height: 100vh;
}
.error-container__oneline-text {
  background-color: #000000;
  color: #ca1135;
  border: 0.1rem solid #d63447;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 3rem;
  margin: 1rem auto;
  text-align: center;
}
.error-container__button {
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0.8rem auto 0 auto;
}
.error-container__button button {
  cursor: pointer;
  margin: 0 auto;
  background-color: #ff928f;
  padding: 0.5rem 3rem;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 3rem;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
}
.error-container__button button:hover {
  background-color: #ff7975;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.separator {
  width: 100%;
  margin: 1rem 0;
  height: 0.2rem;
  background-color: rgba(153, 153, 153, 0.205);
}
img {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.select-input {
  padding: 2rem 2rem 0 2rem;
}
.select-input__control {
  background-color: transparent !important;
}
.select-input__control--is-focused {
  border-color: #df2318 !important;
  box-shadow: none !important;
}
.select-input__single-value {
  color: whitesmoke !important;
}
.select-input__option {
  cursor: pointer;
}
.select-input__option--is-focused {
  background-color: #df2318 !important;
}
.select-input__option--is-selected {
  background-color: #df2318 !important;
}
.select-input__menu {
  background-color: #222 !important;
}
