.main-page {
  width: 100%;
  height: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: 4rem;
}
.main-page__bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}
.main-page__technical-details {
  width: 40%;
  padding-left: 2rem;
}
.main-page__technical-details .title {
  background-color: rgba(153, 153, 153, 0.205);
  padding: 0.7rem 3rem;
  font-size: 2.5rem;
  margin: 0;
  color: #ccc;
}
.main-page__block {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
.main-page__block--image-container {
  width: 60%;
}
.main-page__block--image-container .img {
  max-width: 100%;
  height: auto;
}
.main-page__block--text-container {
  width: 40%;
  display: flex;
  align-self: center;
  flex-direction: column;
  padding: 1rem 0 1rem 3rem;
}
.main-page .main-title {
  font-size: 4rem;
  text-align: center;
  margin-bottom: 6rem;
}
.main-page .text-intro {
  font-size: 1.6rem;
  font-style: italic;
  font-family: "Open Sans Light";
  text-align: justify;
  max-width: 45rem;
}
.main-page .text-intro:last-of-type {
  text-align: center;
}
.main-page .text-title {
  font-size: 3.5rem;
  font-weight: 600;
  margin-top: 4rem;
  text-transform: uppercase;
}
.main-page .text-sub-title {
  font-size: 2.4rem;
  font-weight: 600;
  text-transform: uppercase;
}
.main-page .text-common {
  font-size: 1.6rem;
  line-height: 3rem;
  text-align: justify;
}
.main-page .text-cite {
  font-style: italic;
  text-align: center;
  font-size: 2.6rem;
  margin-top: 10rem;
  margin-bottom: 10rem;
  font-weight: 600;
  color: #df2318;
}
@media screen and (max-width: 968px) {
  .main-page__technical-details {
    margin-top: 4rem;
    width: 100%;
    padding-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .main-page__block--image-container {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .main-page__block--text-container {
    width: 100%;
    padding-left: 0;
    align-items: center;
  }
}
@media screen and (max-width: 576px) {
  .main-page {
    padding: 4rem 2rem;
  }
  .main-page .text-title {
    font-size: 3rem;
  }
  .main-page .text-sub-title {
    font-size: 2.2rem;
  }
  .main-page .text-common {
    line-height: 2.6rem;
    font-size: 1.4rem;
  }
  .main-page .text-cite {
    font-size: 2rem;
  }
}
