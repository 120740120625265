.images-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}
.images-view .image-frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 8rem;
  border: 0.2rem solid rgba(170, 170, 170, 0.1);
  padding: 1.5rem;
}
.images-view .image-frame__image {
  max-width: 600px;
  width: 100%;
  height: auto;
}
.images-view .image-frame__type-name {
  padding: 1.5rem 0 1.5rem 0;
  font-size: 4rem;
  text-transform: uppercase;
  border-bottom: 0.1rem solid #ccc;
  margin-bottom: 3rem;
  width: 100%;
  text-align: center;
  max-width: 50rem;
  letter-spacing: 2rem;
}
@media screen and (max-width: 576px) {
  .images-view .image-frame {
    padding: 0;
  }
  .images-view .image-frame__type-name {
    font-size: 2.5rem;
    letter-spacing: 1rem;
  }
}
