.showcase {
  color: #ccc;
  width: 60%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.showcase__title {
  font-size: 2.5rem;
  background-color: rgba(153, 153, 153, 0.205);
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0.7rem 3rem;
}
.showcase__text-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 2rem;
  padding: 1rem 1.5rem 1rem 0;
  position: relative;
}
.showcase__text-container .line {
  position: absolute;
  left: 1.3rem;
  display: block;
  width: 0.4rem;
  top: 1.5rem;
  height: 0%;
  background-color: #df2318;
}
.showcase__text-container--dot {
  min-width: 3rem;
  max-width: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: stretch;
}
.showcase__text-container--dot .circle {
  display: block;
  width: 1rem;
  height: 1rem;
  background-color: #df2318;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.showcase__text-container--date {
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 0.1rem solid rgba(153, 153, 153, 0.205);
  min-width: 5.675rem;
  max-width: 5.675rem;
}
.showcase__text-container--place {
  font-size: 1.8rem;
  color: #df2318;
}
.showcase__text-container--place .city {
  color: #ccc;
}
.showcase__list--sub-title {
  font-size: 2rem;
  padding-top: 2.5rem;
  border-bottom: 1px solid rgba(153, 153, 153, 0.205);
  padding-bottom: 0.7rem;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 968px) {
  .showcase {
    width: 100%;
  }
  .showcase__text-container {
    border-right: none;
  }
}
.animate-in-showcase {
  width: 100%;
}
.animate-in-showcase .showcase__text-container .line {
  -webkit-animation: grow-line 200ms linear 1 normal forwards;
  -moz-animation: grow-line 200ms linear 1 normal forwards;
  -ms-animation: grow-line 200ms linear 1 normal forwards;
  -o-animation: grow-line 200ms linear 1 normal forwards;
  animation: grow-line 200ms linear 1 normal forwards;
}
@keyframes grow-line {
  0% {
    height: 0%;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
