.layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
}
.layout .threads {
  position: fixed;
  top: 1.5rem;
  right: 2rem;
  z-index: 200;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 0 2rem 0.5rem rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 2rem 0.5rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 2rem 0.5rem rgba(0, 0, 0, 0.5);
}
.layout__sidemenu {
  height: 100vh;
  width: 16rem;
  padding-top: 2rem;
  -webkit-transition: transform 0.3s linear;
  -moz-transition: transform 0.3s linear;
  -ms-transition: transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
  display: inline-block;
}
.layout__sidemenu .nav-item {
  width: 100%;
  padding: 1.2rem 2rem;
  font-size: 1.4rem;
  font-weight: 600;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #ccc;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.layout__sidemenu .nav-item:hover {
  background-color: #df2318;
}
.layout__container {
  width: calc(100vw - 16rem);
  height: 100vh;
  overflow: auto;
  display: inline-block;
}
.layout__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  left: 0;
  z-index: 100;
  display: block;
}
@media screen and (max-width: 1200px) {
  .layout .threads {
    display: block;
  }
  .layout__sidemenu {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 5000;
    background-color: #111;
    overflow-y: auto;
  }
  .layout__container {
    width: 100%;
  }
}
.layout-opened .layout__sidemenu {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}
